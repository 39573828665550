import React from "react";
import "./footer.css";

function Footer() {
  return (
    <section className="bg-secondary-focus mt-10 pt-4 pb-4 glass">
      <div className="container px-4 sm:px-6 lg:px-10 mx-auto">
        <div className="flex justify-center space-x-6 sm:space-x-9">
          <div className="flex items-center">
            <h3 className="font-bold text-xl sm:text-2xl md:text-3xl text-center">
              Lake of Dreams ©2025
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
