import React, { useState, useEffect } from "react";
import "./nav.css";
import logo from "../../assets/header.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Nav() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "mobile", // Disable animations on mobile for performance
      duration: 600, // Slightly faster for snappier feel
    });

    const handleScroll = () => {
      setIsSticky(window.scrollY > 50); // Trigger sticky after slight scroll
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`container mx-auto px-4 sm:px-6 lg:px-8 ${
        isSticky ? "sticky scrolled" : "top-nav"
      }`}
      data-aos="fade-down"
    >
      <div className="flex justify-between items-center w-full">
        <img
          src={logo}
          alt="Lake of Dreams Logo"
          className="h-9 sm:h-11 w-auto logo-glow hover:scale-105 transition duration-300 ease-in-out"
          data-aos="zoom-in"
          data-aos-delay="200"
        />

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-6 lg:space-x-10">
          <a
            href="#home"
            className="nav-link text-base lg:text-lg"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            Home
          </a>
          <a
            href="#about"
            className="nav-link text-base lg:text-lg"
            data-aos="fade-left"
            data-aos-delay="400"
          >
            About
          </a>
          <a
            href="#retreats"
            className="nav-link text-base lg:text-lg"
            data-aos="fade-left"
            data-aos-delay="500"
          >
            Retreats
          </a>
          <a
            href="#picnic"
            className="nav-link text-base lg:text-lg"
            data-aos="fade-left"
            data-aos-delay="600"
          >
            Picnic
          </a>
          <a
            href="#contact"
            className="nav-link text-base lg:text-lg"
            data-aos="fade-left"
            data-aos-delay="700"
          >
            Contact
          </a>
        </div>

        {/* Mobile Navigation */}
        <div className="md:hidden">
          <label tabIndex={0} className="btn btn-ghost btn-circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-vertical dropdown-content mt-2 p-3 shadow-lg bg-white rounded-lg w-56 absolute right-2 mobile-menu"
          >
            <li>
              <a href="#home" className="nav-link-mobile py-2 text-base">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="nav-link-mobile py-2 text-base">
                About
              </a>
            </li>
            <li>
              <a href="#retreats" className="nav-link-mobile py-2 text-base">
                Retreats
              </a>
            </li>
            <li>
              <a href="#picnic" className="nav-link-mobile py-2 text-base">
                Picnic
              </a>
            </li>
            <li>
              <a href="#contact" className="nav-link-mobile py-2 text-base">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;